import React, { Component } from 'react';
import { Field, reduxForm, initialize } from 'redux-form';
import { Tabs, Row, Col, Divider, Icon, Collapse, notification } from 'antd';
import { withLocalize } from 'react-localize-redux';
import {
  GetTemplate,
  UpdateTemplate,
  CreateTemplate
} from '../../infra/requests/InstitutionsTemplatesRequests';
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  DefaultLanguageTab
} from '../../styles/BasicStyles';
import FormValidator from '../../infra/services/validations/FormValidator';
import ManageFormHeader from '../../components/base/ManageFormHeader';
import { FlattenToFormData } from '../../infra/services/formdata/TransformToFormData';
import TextInput from '../../components/generic/inputs/TextInput';
import { GetTranslationDescription } from '../../infra/services/translations/AvailableTranslations';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CloseMenu } from "../../redux/Menu/menu.actions";
import TextAreaInput from '../../components/generic/inputs/TextAreaInput';
import DraftInput from '../../components/generic/inputs/DraftInput';
const TabPane = Tabs.TabPane;
const Panel = Collapse.Panel;

const validations = FormValidator.make({
  title: 'required',
  subject: 'required',
  content: 'required'
});

class ManageInstitutionTemplatePage extends Component {
  state = { 
    isNew: false, 
    loading: true
  };

  componentDidMount = async () => {
    const {
      match: { params },
      dispatch,
      CloseMenu,
      user,
      history
    } = this.props;

    if(user.type === 2 || user.type === 3) {
      return history.push('/');
    }

    CloseMenu();

    if(params.id) {
      const result = await GetTemplate(params.id);
      dispatch(
        initialize('manage_institution_template_form', {
          ...result.data,
        })
      );

      this.setState({ loading: false });
    } 
    else {
      this.setState({
        isNew: true,
        loading: false
      });
    }
  };

  onSubmit = async (values) => {
    try {
      this.setState({ loading: true });
      const { match: { params }, history } = this.props;

      // Replace empty <p> tags to make sure line breaks are respected
      values.content = values.content.replace(/<p>\s*<\/p>/g, "<p>&nbsp;</p>");

      const payload = FlattenToFormData(values);
      const { success } = params.id ? await UpdateTemplate(params.id, payload) : await CreateTemplate(payload);

      if (success) return history.push('/institutions-templates');
      return this.setState({ loading: false });
    } 
    catch (e) {
      console.error(e);
      this.setState({ loading: false });
    }
  };

  handleOnCancel = async () => {
    const { history } = this.props;
    return history.push('/institutions-templates');
  };

  renderTag = (tag, title, isFirst = false) => {
    return (
      <div style={{ marginTop: isFirst ? '0px' : '10px' }}>
        <Icon 
          type='copy' 
          style={{ cursor: 'pointer', fontSize: '18px', marginRight: '8px' }} 
          title='Copiar tag'
          onClick={() => navigator.clipboard.writeText(tag).then(() => notification.success({ message: "Copiado com sucesso!" }) )}
        /> 
        <span style={{ fontWeight: 700 }}>{tag}</span> - {title}
      </div>
    );
  };

  render() {
    const { handleSubmit, activeLanguage, languages, pristine } = this.props;
    const { isNew, loading } = this.state;

    if(loading) return <SpinLoading />;
    const title = isNew ? 'Adicionar' : 'Editar';

    return (
      <React.Fragment>
        <ManageFormHeader
          title='Templates para Instituições'
          breadcrumbs={[title]}
          buttons={[
            {
              type: 'primary',
              icon: 'save',
              text: 'Gravar',
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine
            },
            {
              type: 'secondary',
              icon: 'close',
              text: 'Cancelar',
              onClick: this.handleOnCancel
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={24}>
              <Col xs={24}>
                <Field
                  component={TextInput}
                  name={'title'}
                  type='text'
                  label={'Título *'}
                  placeholder={'Título'}
                />
              </Col>
            </Row>
            <Divider />
            <Collapse>
              <Panel header="Tags disponíveis" key="1" style={{ backgroundColor: '#d9d9d9' }}>
                {this.renderTag('{{params.ref}}', 'Referência do processo', true)}
                {this.renderTag('{{params.avs}}', 'Nº de AVS do cliente')}
                {this.renderTag('{{params.name}}', 'Nome do cliente')}
                {this.renderTag('{{params.birthday}}', 'Data de nascimento do cliente')}
              </Panel>
            </Collapse>
            <Row gutter={24} style={{ marginTop: '20px' }}>
              <Col xs={24}>
                <Field
                  component={TextInput}
                  name={'subject'}
                  type='text'
                  label={'Assunto do Email *'}
                  placeholder={'Assunto'}
                />
              </Col>
            </Row>
            <Row gutter={24} style={{ marginTop: '20px' }}>
              <Col xs={24}>
                <Field
                  component={DraftInput}
                  name={'content'}
                  label={'Conteúdo do Email *'}
                  placeholder={'Conteúdo'}
                />
              </Col>
            </Row>
            <Divider />
            <Row gutter={24}>
              <Col md={12} xs={24} offset={0}>
                <DefaultLanguageTab>
                  {GetTranslationDescription(activeLanguage?.code)}
                </DefaultLanguageTab>
                <Field
                  component={TextAreaInput}
                  name={`notification_content.${activeLanguage?.code}`}
                  label={'Notificação para Cliente'}
                  placeholder={'Conteúdo'}
                />
              </Col>
              <Col md={12} xs={24} offset={0}>
                <Tabs>
                  {languages
                    .filter((x) => !x.active)
                    .map((language) => (
                      <TabPane
                        tab={GetTranslationDescription(language.code)}
                        key={language.code}
                      >
                        <Field
                          component={TextAreaInput}
                          name={`notification_content.${language.code}`}
                          label={'Notificação para Cliente'}
                          placeholder={'Conteúdo'}
                        />
                      </TabPane>
                    ))}
                </Tabs>
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageInstitutionTemplatePage = reduxForm({
  form: 'manage_institution_template_form',
  validate: validations,
})(ManageInstitutionTemplatePage);

const mapStateToProps = (state) => ({
  user: state.user
});

const mapActionToProps = (dispatch) => bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(connect(mapStateToProps, mapActionToProps)(ManageInstitutionTemplatePage));